import Table from "../../components/Table/Table";
import Get from "../../util/Request/Get";
import { SERVICES } from "../../util/Routes/Routes";
import { useQuery } from "@tanstack/react-query";
import { messageError } from "../../util/Toast";
import { ButtonActionRenderer } from "../../components/Table/ButtonActionRenderer";
import { useTranslation } from "react-i18next";
import { useMemo, useEffect, useState } from "react";
import i18next from "i18next";
import ServicesModal from "../../modals/ServicesModal";
import { AddSharp as AddIcon } from "@mui/icons-material";
import { Fab, Box } from "@mui/material";

export default function Services() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const onClose = () => setOpen(false);
  const getServicesByLanguage = () =>
    Get({
      route: `${SERVICES}/getTitlesByLanguage/${i18next.language}`,
    });
  const { data, refetch } = useQuery({
    queryKey: ["services"],
    queryFn: getServicesByLanguage,
    onError: (error) => {
      messageError();
    },
  });

  const columnDefs = [
    { headerName: "Target", field: "target", flex: 1 },
    { headerName: "Title", field: "title", flex: 1 },

    {
      headerName: "Actions",
      field: "actions",
      cellRenderer: ButtonActionRenderer,
      cellRendererParams: {
        modalName: "ServicesModal",
        showDeleteBtn: true,
        route: SERVICES,
        queryKey: ["services"],
      },
    },
  ];
  const list = useMemo(() => {
    const array = [];
    data?.list.forEach((item) => {
      const split = item.label.split(".");
      array.push({
        target: t(`shared.term.${split[1]}`),
        title: item.value,
        id: split[2],
      });
    });
    return array;
  }, [data, t]);

  useEffect(() => {
    refetch();
  }, [i18next.language, refetch]);

  return (
    <>
      <ServicesModal open={open} onClose={onClose} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Fab
          color="success"
          onClick={() => setOpen(true)}
          aria-label="create"
          size="large"
          sx={{
            marginBottom: "10px",
          }}
        >
          <AddIcon fontSize="medium" />
        </Fab>
      </Box>

      <Table data={list} columnDefs={columnDefs} />
    </>
  );
}
