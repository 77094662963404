import Table from "../../components/Table/Table";
import Get from "../../util/Request/Get";
import { SKILLS } from "../../util/Routes/Routes";
import { useQuery } from "@tanstack/react-query";
import { messageError } from "../../util/Toast";
import { ButtonActionRenderer } from "../../components/Table/ButtonActionRenderer";
import { useTranslation } from "react-i18next";
import { useMemo, useEffect, useState } from "react";
import i18next from "i18next";
import { AddSharp as AddIcon } from "@mui/icons-material";
import { Fab, Box } from "@mui/material";
import SkillsModal from "../../modals/SkillsModal";

export default function Skills() {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const onClose = () => setOpen(false);
    const getSkillsByLanguage = () =>
        Get({
            route: `${SKILLS}/getTitlesByLanguage/${i18next.language}`,
        });
    const { data, refetch } = useQuery({
        queryKey: ["skills"],
        queryFn: getSkillsByLanguage,
        onError: (error) => {
            messageError();
        },
    });

    const columnDefs = [
        { headerName: "Title", field: "title", flex: 1 },

        {
            headerName: "Actions",
            field: "actions",
            cellRenderer: ButtonActionRenderer,
            cellRendererParams: {
                modalName: "SkillsModal",
                showDeleteBtn: true,
                route: SKILLS,
                queryKey: ["skills"],
            },
        },
    ];
    const list = useMemo(() => {
        const array = [];
        data?.list.forEach((item) => {
            const labelArray = item.label.split(".")
            const id = labelArray[1]
            array.push({
                title: item.value,
                id: id,
            });
        });
        return array;
    }, [data, t]);

    useEffect(() => {
        refetch();
    }, [i18next.language, refetch]);


    return (
        <>
            <SkillsModal open={open} onClose={onClose} />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}
            >
                <Fab
                    color="success"
                    onClick={() => setOpen(true)}
                    aria-label="create"
                    size="large"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    <AddIcon fontSize="medium" />
                </Fab>
            </Box>

            <Table data={list} columnDefs={columnDefs} />
        </>
    );
}
