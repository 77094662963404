import { Outlet } from "react-router-dom";
import { useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Nav from "../Nav/Nav";

const mdTheme = createTheme();

const PageLayout = () => {
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  return location.pathname === "/" || !sessionStorage.token ? (
    <Outlet />
  ) : (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Nav open={open} toggleDrawer={toggleDrawer} />
        <Sidebar open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Container maxWidth={false} sx={{ pt: 18, pb: 18, height: "100%" }}>
            {<Outlet />}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default PageLayout;
