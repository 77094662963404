import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useCallback } from "react";
import { Box, Button, TextField } from "@mui/material";
import Get from "../../../../util/Request/Get";
import { useQuery } from "@tanstack/react-query";
import { messageError } from "../../../../util/Toast";
import { SETTINGS } from "../../../../util/Routes/Routes";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

export default function Contact({
  setData,
  errors,
  validateEmail,
  validatePhoneNumber,
}) {
  const { t } = useTranslation();

  const getSettingsByType = () =>
    Get({
      route: `${SETTINGS}/getSettingsByType`,
      key: "contact",
    });

  const { data: result, isLoading } = useQuery({
    queryKey: ["settings-contact"],
    queryFn: getSettingsByType,
    onError: (error) => {
      messageError();
    },
  });
  const data = result?.list;
  const getIndex = useCallback(
    (db_label) => {
      return data?.findIndex((contact) => {
        if (contact.label === db_label) {
          return contact;
        }
        return null;
      });
    },
    [data]
  );

  const [email, setEmail] = useState({});
  const [phone_number, setPhoneNumber] = useState({});
  const [address, setAddress] = useState({});
  useEffect(() => {
    if (!isLoading) {
      setEmail(data[getIndex("email")]);
      setPhoneNumber(data[getIndex("phone_number")]);
      setAddress(data[getIndex("address")]);
    }
  }, [isLoading, data, getIndex]);

  useEffect(() => {
    setData((prev) => ({ ...prev, email, phone_number, address }));
  }, [email, phone_number, address, setData]);
  return (
    <Box>
      toto
      <Grid container spacing={1}>
        <Grid xs={4}>
          <TextField
            type="text"
            label={t(`settings.contact.email`)}
            placeholder={t(`shared.term.helper`)}
            variant="outlined"
            fullWidth
            required
            error={!!errors.email}
            helperText={errors.email}
            value={email?.value || ""}
            onChange={(e) => {
              setEmail((prev) => ({ ...prev, value: e.target.value }));
              validateEmail(e.target.value);
            }}
          />
        </Grid>
        <Grid xs={4}>
          <TextField
            type="text"
            label={t(`settings.contact.phone_number`)}
            placeholder={t(`shared.term.helper`)}
            variant="outlined"
            fullWidth
            required
            error={!!errors.phone_number}
            helperText={errors.phone_number}
            value={phone_number?.value || ""}
            onChange={(e) => {
              setPhoneNumber((prev) => ({ ...prev, value: e.target.value }));
              validatePhoneNumber(e.target.value);
            }}
          />
        </Grid>
        <Grid xs={4}>
          <TextField
            type="text"
            label={t(`settings.contact.address`)}
            placeholder={t(`shared.term.helper`)}
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            required
            error={!!errors.address}
            helperText={t(`shared.term.required`)}
            value={address?.value || ""}
            onChange={(e) => {
              setAddress((prev) => ({ ...prev, value: e.target.value }));
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
