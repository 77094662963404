import { Modal, Paper } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  minWidth: "50%",
  overflowY: "auto",
  maxHeight: "90vh",
};

export default function BaseModal({ children, open, onClose }) {
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper sx={style}>{children}</Paper>
      </Modal>
    </>
  );
}
