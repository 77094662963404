import api from "../Environment.js";

/**
 * Delete
 * @param key
 * @param {string} route - string
 * @param {string} type - string (optional)
 * @return {JSON} res - JSON
 */
async function Delete({ key, route, type = `json` }) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": `application/${type}`,
      Authorization: `Bearer ${sessionStorage?.token}`,
    },
  };

  const res = await fetch(`${api}${route}/${key}`, requestOptions);
  if (!res.ok) {
    const errorMessage = await res.json();
    throw new Error(errorMessage.message || "Failed to perform mutation");
  }
  return res.json();
}
export default Delete;
