import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useUser from "../../context/User";
import Cookies from "js-cookie";
import { isEmail, isEmpty } from "../../util/Validation";
import { useEffect } from "react";
import { Modal, Paper } from "@mui/material";
import Post from "../../util/Request/Post";
import { PASSWORD } from "../../util/Routes/Routes";
import { messageSuccess, messageError } from "../../util/Toast";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const theme = createTheme();
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
};
export default function Login() {
  const { login, loginError } = useUser();
  const [remember, setRemember] = useState(!!Cookies.get("email"));
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({
    email: Cookies.get("email"),
    password: "",
    forgottenPasswordEmail: "",
  });
  const [openForgottenPasswordModal, setOpenForgottenPasswordModal] =
    useState(false);
  const { t } = useTranslation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    validation("email");
    validation("password");

    if (!Object.values(errors).length) {
      login(data.email, data.password);

      remember
        ? Cookies.set("email", data.email, { secure: true })
        : Cookies.remove("email");
    }
  };
  useEffect(() => {
    if (loginError && loginError.message !== "Failed to fetch") {
      const validationErrors = {};
      validationErrors.passwordError = loginError.message;
      validationErrors.emailError = " ";

      setErrors((prevErrors) => ({ ...prevErrors, ...validationErrors }));
    }
  }, [loginError]);
  const sendForgottenPasswordEmail = () =>
    Post({
      route: `${PASSWORD}/sendResetLinkEmail`,
      body: {
        email: data.forgottenPasswordEmail,
      },
    });

  const mutation = useMutation({
    mutationFn: sendForgottenPasswordEmail,
    onSuccess: (data) => {
      setOpenForgottenPasswordModal(false);
      setData((prevDatas) => ({ ...prevDatas, forgottenPasswordEmail: "" }));
      messageSuccess("mail sent");
    },
    onError: (error, variables, context) => {
      messageError("failed to send email");
    },
  });
  const validation = (name, value) => {
    const text = value === undefined ? data[name] : value;
    if (isEmpty(text)) {
      errors[`${name}Error`] = t("shared.term.required");
    } else if (!isEmail(text) && name !== "password") {
      errors[`${name}Error`] = t("shared.term.invalid");
    } else {
      delete errors[`${name}Error`];
    }

    setErrors({ ...errors });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Modal
          open={openForgottenPasswordModal}
          onClose={() => {
            setOpenForgottenPasswordModal(false);
            setData((prevDatas) => ({
              ...prevDatas,
              forgottenPasswordEmail: "",
            }));
            delete errors.forgottenPasswordEmailError;
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Paper style={style} sx={{ padding: "1em" }}>
            <TextField
              value={data.forgottenPasswordEmail}
              helperText={errors.forgottenPasswordEmailError}
              error={!!errors.forgottenPasswordEmailError}
              onChange={(e) => {
                setData((prevDatas) => ({
                  ...prevDatas,
                  forgottenPasswordEmail: e.target.value,
                }));

                validation("forgottenPasswordEmail", e.target.value);
              }}
              label={"email"}
            />
            <Button
              variant="contained"
              onClick={() => {
                validation("forgottenPasswordEmail");

                if (!errors.forgottenPasswordEmailError) {
                  mutation.mutate();
                }
              }}
            >
              {t("shared.btn.send")}
            </Button>
          </Paper>
        </Modal>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              error={Boolean(errors.emailError)}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={data.email || ""}
              onChange={(e) => {
                setData((prevDatas) => ({
                  ...prevDatas,
                  email: e.target.value,
                }));
                validation("email", e.target.value);
              }}
              helperText={errors.emailError}
            />
            <TextField
              error={Boolean(errors.passwordError)}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={data.password}
              onChange={(e) => {
                setData((prevDatas) => ({
                  ...prevDatas,
                  password: e.target.value,
                }));

                validation("password", e.target.value);
              }}
              helperText={errors.passwordError}
            />
            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  checked={remember}
                  onChange={() => setRemember(!remember)}
                  color="primary"
                />
              }
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link
                  href="#"
                  variant="body2"
                  onClick={() => setOpenForgottenPasswordModal(true)}
                >
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
