import "ag-grid-community/styles//ag-grid.css";
import "ag-grid-community/styles//ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";

/* https://www.maxrosenb.com/blog/aggrid-reactquery-tutorial*/

const Table = ({ data, columnDefs }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [columnApi, setColumnApi] = useState(null);

  const onGridReady = (params) => {
    const { columnApi } = params;
    columnApi?.autoSizeColumn("actions", false);
    setColumnApi(columnApi);
  };

  useEffect(() => {
    if (columnApi && isMobile) {
      setTimeout(() => {
        columnApi.autoSizeAllColumns();
      }, 0);
    }
  }, [columnApi, isMobile, data]);
  return (
    <Box
      className="ag-theme-alpine"
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <AgGridReact
        onGridReady={onGridReady}
        rowHeight={50}
        defaultColDef={{
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
          sortable: true,
          filter: true,
          floatingFilter: true,
          resizable: true,
        }}
        pagination={true}
        paginationAutoPageSize={true}
        columnDefs={columnDefs}
        rowData={data}
      />
    </Box>
  );
};
export default Table;
