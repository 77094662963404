import { Typography, Container, Button, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useMemo } from "react";

export default function VerifyEmail() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { status } = useParams();
  const message = useMemo(() => {
    let text = "There's nothing here: 404!";
    if (status === "success") {
      text = "Your email has verified";
    } else if (status === "already-success") {
      text = "Your email has already been verified";
    }
    return text;
  }, [status]);
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          gap: 5,
          alignItems: "center",
        }}
      >
        <Typography component="h1">{message}</Typography>
        <Button variant="contained" onClick={() => navigate("/")}>
          {t("shared.btn.return_to_login")}
        </Button>
      </Box>
    </Container>
  );
}
