import api from "../Environment.js";

/**
 * Put
 * @param {JSON} body - JSON
 * @param {string} route - string
 * @param {string} type - string (optional)
 * @param  key
 * @return {JSON} res - JSON
 */
async function Put({ body, route, type = `json`, key }) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": `application/${type}`,
      Authorization: `Bearer ${sessionStorage?.token}`,
    },
    body: JSON.stringify(body),
  };

  const res = await fetch(`${api}${route}/${key}`, requestOptions);
  if (!res.ok) {
    const errorMessage = await res.json();
    throw new Error(errorMessage.message || "Failed to perform mutation");
  }
  return res.json();
}
export default Put;
