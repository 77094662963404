import * as React from "react";
import Table from "../../components/Table/Table";
import Get from "../../util/Request/Get";
import { USERS } from "../../util/Routes/Routes";
import { useQuery } from "@tanstack/react-query";
function Users() {
  const getUsers = () => Get({ route: USERS });
  const { data, error, isLoading } = useQuery({
    queryKey: ["users"],
    queryFn: getUsers,
    // enabled: !!currentUser?.token, // The query will not execute until the currentUser?.token exists
  });
  //console.log(data);
  const columnDefs = [
    { headerName: "Brand", field: "username" },
    { headerName: "Model", field: "model" },
    { headerName: "Price", field: "price" },
    { headerName: "Year", field: "year" },
    { headerName: "Color", field: "color" },
    { headerName: "Type", field: "type" },
    { headerName: "Country", field: "country" },
  ];
  return <Table data={data?.users} columnDefs={columnDefs} />;
}

export default Users;
