import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useCallback } from "react";
import { Box, Button, TextField } from "@mui/material";
import Get from "../../../../util/Request/Get";
import { useQuery } from "@tanstack/react-query";
import { messageError } from "../../../../util/Toast";
import { SETTINGS } from "../../../../util/Routes/Routes";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

export default function SocialMedia({ setData, errors }) {
  const { t } = useTranslation();
  const getSettings = () => Get({ route: SETTINGS });

  const {
    data: result,
    isError,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["settings"],
    queryFn: getSettings,
    /* enabled: false, */
    onError: (error) => {
      messageError();
    },
  });
  const data = result?.list;
  const getIndex = useCallback(
    (db_label) => {
      return data?.findIndex((social_media) => {
        if (social_media.label === db_label) {
          return social_media;
        }
        return null;
      });
    },
    [data]
  );

  const [facebook, setFacebook] = useState({});
  const [whatsapp, setWhatsapp] = useState({});
  const [telegram, setTelegram] = useState({});
  const [linkedIn, setLinkedIn] = useState({});
  useEffect(() => {
    if (!isLoading) {
      setFacebook(data[getIndex("facebook_link")]);
      setWhatsapp(data[getIndex("whatsapp_number")]);
      setTelegram(data[getIndex("telegram_link")]);
      setLinkedIn(data[getIndex("linkedin_link")]);
    }
  }, [isLoading, data, getIndex]);

  useEffect(() => {
    setData((prev) => ({ ...prev, facebook, whatsapp, telegram, linkedIn }));
  }, [facebook, whatsapp, telegram, linkedIn, setData]);
  return (
    <Box>
      toto
      <Grid container spacing={1}>
        <Grid xs={4}>
          <TextField
            type="text"
            label={t(`settings.social_media.facebook`)}
            placeholder={t(`shared.term.helper`)}
            variant="outlined"
            fullWidth
            required
            error={!!errors.facebook}
            helperText={t(`shared.term.required`)}
            value={facebook?.value || ""}
            onChange={(e) => {
              setFacebook((prev) => ({ ...prev, value: e.target.value }));
            }}
          />
        </Grid>
        <Grid xs={4}>
          <TextField
            type="text"
            label={t(`settings.social_media.whatsapp`)}
            placeholder={t(`shared.term.helper`)}
            variant="outlined"
            fullWidth
            required
            error={!!errors.whatsapp}
            helperText={t(`shared.term.required`)}
            value={whatsapp?.value || ""}
            onChange={(e) => {
              setWhatsapp((prev) => ({ ...prev, value: e.target.value }));
            }}
          />
        </Grid>
        <Grid xs={4}>
          <TextField
            type="text"
            label={t(`settings.social_media.telegram`)}
            placeholder={t(`shared.term.helper`)}
            variant="outlined"
            fullWidth
            required
            error={!!errors.telegram}
            helperText={t(`shared.term.required`)}
            value={telegram?.value || ""}
            onChange={(e) => {
              setTelegram((prev) => ({ ...prev, value: e.target.value }));
            }}
          />
        </Grid>
        <Grid xs={4}>
          <TextField
            type="text"
            label={t(`settings.social_media.linkedIn`)}
            placeholder={t(`shared.term.helper`)}
            variant="outlined"
            fullWidth
            required
            error={!!errors.linkedIn}
            helperText={t(`shared.term.required`)}
            value={linkedIn?.value || ""}
            onChange={(e) => {
              setLinkedIn((prev) => ({ ...prev, value: e.target.value }));
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
