import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";

function TabsComponent({ tabContent, errors = [] }) {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <Box>
      <Box>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {Object.keys(tabContent).map((key, i) => (
            <Tab
              sx={{
                color: errors.includes(key) && "red",
                fontWeight: errors.includes(key) && "bold",
              }}
              label={key}
              key={i}
            />
          ))}
        </Tabs>
      </Box>
      <Box sx={{ padding: 2 }}>
        {Object.values(tabContent).map(
          (value, i) => tabIndex === i && <Box key={i}>{value}</Box>
        )}
      </Box>
    </Box>
  );
}

export default TabsComponent;
