import { Typography, Container, Button, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Post from "../../util/Request/Post";
import { useMutation } from "@tanstack/react-query";
import { EMAIL } from "../../util/Routes/Routes";
import { messageSuccess, messageError } from "../../util/Toast";

export default function VerifyEmailBeforeAccess() {
  const { t } = useTranslation();

  const resendVerificationLink = () =>
    Post({ route: `${EMAIL}/verify/resend` });
  const { mutate } = useMutation({
    mutationFn: resendVerificationLink,
    onSuccess: (data) => {
      messageSuccess("email sent");
    },
    onError: (error, variables, context) => {
      messageError("failed to send email");
    },
  });
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 16,
          display: "flex",
          flexDirection: "column",
          gap: 5,
          alignItems: "center",
        }}
      >
        <Typography component="h1">
          {"Please verify you email first before accessing your account"}
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            mutate();
          }}
        >
          {t("shared.btn.resend_verification_link")}
        </Button>
      </Box>
    </Container>
  );
}
