import { Outlet, Navigate } from "react-router-dom";
import useUser from "../../context/User";
import VerifyEmailBeforeAccess from "../../pages/VerifyEmail/VerifyEmailBeforeAccess";
import { loader } from "../../context/Loader";
/* https://www.robinwieruch.de/react-router-private-routes/ */

export const ProtectedRoute = ({ isAllowed, redirectPath = "/", children }) => {
  const { currentUser } = useUser();

  if (!currentUser) {
    return loader(true);
  } else if (!currentUser?.email_verified_at) {
    return <VerifyEmailBeforeAccess />;
  } else if (
    !sessionStorage.token ||
    (isAllowed !== undefined && currentUser?.role.label !== isAllowed)
  ) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};
