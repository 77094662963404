import Table from "../../../components/Table/Table";
import Get from "../../../util/Request/Get";
import { TRANSLATIONS } from "../../../util/Routes/Routes";
import { useQuery } from "@tanstack/react-query";
import { ButtonActionRenderer } from "../../../components/Table/ButtonActionRenderer";
import { messageError } from "../../../util/Toast";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo } from "react";
import * as React from "react";
import { IconButton, List, ListItem, ListItemText, Paper } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import i18next from "i18next";

export default function SharedContents() {
  const { t } = useTranslation();

  const getSharedTranslations = () =>
    Get({
      route: `${TRANSLATIONS}/getSharedComponents`,
    });
  const { data, refetch } = useQuery({
    queryKey: ["shared-contents"],
    queryFn: getSharedTranslations,
    onError: (error) => {
      messageError();
    },
  });
  const columnDefs = [
    { headerName: "Component", field: "translation", flex: 1 },
    {
      headerName: "Actions",
      field: "actions",
      cellRenderer: ButtonActionRenderer,
      cellRendererParams: {
        modalName: "TextContentModal",
        showDeleteBtn: false,
      },
    },
  ];

  const list = useMemo(() => {
    const array = [];
    data?.list.map((item) => {
      array.push({
        translation: t(`component.name.${item.translation}`),
        value: item.value,
        label: item.label,
      });
    });
    return array;
  }, [data, t]);

  useEffect(() => {
    refetch();
  }, [i18next.languages, refetch]);

  return (
    <>
      <Table data={list} columnDefs={columnDefs} />
      <Paper>
        <List>
          {list.map((element) =>
            React.cloneElement(
              <ListItem
                secondaryAction={
                  <IconButton edge="end" aria-label="edit">
                    {/* <ButtonActionRenderer data={element}/> */}
                  </IconButton>
                }
              >
                {console.log(element)}
                <ListItemText primary={element.translation} />
              </ListItem>,
              { key: element.translation }
            )
          )}
        </List>
      </Paper>
    </>
  );
}
