import React, { useState } from "react";

import { Button, Box, Container, TextField, IconButton } from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { isStrongPassword, isEmpty } from "../../util/Validation";
import { useMutation } from "@tanstack/react-query";
import { useParams, useNavigate } from "react-router-dom";
import Post from "../../util/Request/Post";
import { PASSWORD } from "../../util/Routes/Routes";
import { messageError, messageSuccess } from "../../util/Toast";
import { useTranslation } from "react-i18next";
export default function ResetPassword() {
  const [errors, setErrors] = useState({});
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { token, email } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  const validatePassword = (value) => {
    const data = value === undefined ? password : value;
    if (isEmpty(data)) {
      errors.passwordError = t("shared.term.required");
    } else if (!isStrongPassword(data)) {
      const defaultOptions = {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      };
      const missingChars = [];

      if (!/(?=.*[a-z])/.test(data)) {
        missingChars.push("lowercase");
      }

      if (!/(?=.*[A-Z])/.test(data)) {
        missingChars.push("uppercase");
      }

      if (!/(?=.*\d)/.test(data)) {
        missingChars.push("number");
      }

      if (!/[!@#$%^&*]/.test(data)) {
        missingChars.push("special character (!@#$%^&*)");
      }
      if (value.length < defaultOptions.minLength) {
        missingChars.push("minimum length");
      }
      const errorMessages = missingChars.map((missingChar) => {
        if (missingChar === "minimum length") {
          return `The password must be at least ${defaultOptions.minLength} characters long.`;
        }
        return `The password must contain at least one ${missingChar}.`;
      });

      errors.passwordError = errorMessages;
    } else {
      delete errors.passwordError;
    }

    setErrors({ ...errors });
  };

  const validatePasswordConfirmation = (value, passwordValue) => {
    const data = value === undefined ? passwordConfirmation : value;
    if (isEmpty(data)) {
      errors.passwordConfirmationError = t("shared.term.required");
    } else if (data !== (passwordValue || password)) {
      errors.passwordConfirmationError = "password doesn't match";
    } else {
      delete errors.passwordConfirmationError;
    }
    setErrors({ ...errors });
  };

  const resetPassword = () =>
    Post({
      route: `${PASSWORD}/passwordReset`,
      body: {
        password,
        password_confirmation: passwordConfirmation,
        token,
        email,
      },
    });

  const mutation = useMutation({
    mutationFn: resetPassword,
    onSuccess: (data) => {
      messageSuccess("password changed");
      navigate("/");
    },
    onError: (error, variables, context) => {
      messageError();
    },
  });

  const onSubmit = () => {
    validatePassword();
    validatePasswordConfirmation();
    if (!Object.values(errors).length) {
      mutation.mutate();
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box component="main" sx={{ mt: 1 }}>
          <TextField
            error={!!errors.passwordError}
            margin="normal"
            required
            fullWidth
            id="password"
            label="Password"
            name="password"
            type={showPassword ? "text" : "password"}
            autoFocus
            InputProps={{
              endAdornment: (
                <IconButton onClick={togglePasswordVisibility} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              validatePassword(e.target.value);
              if (!isEmpty(passwordConfirmation)) {
                validatePasswordConfirmation(undefined, e.target.value);
              }
            }}
            helperText={
              errors.passwordError?.length ? (
                typeof errors.passwordError === "object" ? (
                  <Box component={"span"}>
                    <Box component={"ul"}>
                      {Object.values(errors.passwordError)?.map(
                        (error, index) => (
                          <Box component={"li"} key={index}>
                            {error}
                          </Box>
                        )
                      )}
                    </Box>
                  </Box>
                ) : (
                  errors.passwordError
                )
              ) : null
            }
          />
          <TextField
            error={!!errors.passwordConfirmationError}
            margin="normal"
            required
            fullWidth
            name="password-confirmation"
            label="Confirm Password"
            type="password"
            id="password-confirmation"
            autoComplete="current-password-confirmation"
            value={passwordConfirmation}
            onChange={(e) => {
              setPasswordConfirmation(e.target.value);
              validatePasswordConfirmation(e.target.value);
            }}
            helperText={errors.passwordConfirmationError}
          />

          <Button
            fullWidth
            variant="contained"
            onClick={onSubmit}
            sx={{ mt: 3, mb: 2 }}
          >
            {t("shared.btn.reset")}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
