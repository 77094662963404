import {
  Modal,
  Paper,
  TextField,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { isEmpty } from "../util/Validation";
import { useState, useMemo, useEffect, useCallback } from "react";
import { LANGUAGES, CUSTOM_FIELD } from "../util/Routes/Routes";
import { messageError, messageSuccess } from "../util/Toast";
import { useQuery, useMutation } from "@tanstack/react-query";
import Get from "../util/Request/Get";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import BaseModal from "./BaseModal";
import { useTranslation } from "react-i18next";
import TabsComponent from "../components/Tabs/Tabs";
import Post from "../util/Request/Post";

export default function CustomFieldModal({ open, onClose }) {
  const [errors, setErrors] = useState({});
  const [componentType, setComponentType] = useState("block");
  const [componentName, setComponentName] = useState("");
  const [contentType, setContentType] = useState("h1");
  const [contentName, setContentName] = useState("");
  const [filteredComponentName, setFilteredComponentName] = useState([]);
  const [translationText, setTranslationText] = useState({});
  const [tabErrors, setTabErrors] = useState({});
  const { t } = useTranslation();

  const getLanguages = () => Get({ route: LANGUAGES });
  const { data: languagesData } = useQuery({
    queryKey: ["languages"],
    queryFn: getLanguages,
    onError: (error) => {
      messageError();
    },
  });
  const createCustomField = () =>
    Post({
      route: CUSTOM_FIELD,
      body: {
        content_type: contentType,
        translations: translationText,
        label: `${componentType}.${componentName}.${contentName}`,
      },
    });

  const mutation = useMutation({
    mutationFn: createCustomField,
    onSuccess: (data) => {
      onClose();
      messageSuccess("created successfully");
    },
    onError: (error, variables, context) => {
      messageError("failed to create");
    },
  });
  const resetFields = () => {
    setComponentType("block");
    setComponentName("");
    setContentType("h1");
    setContentName("");
    setFilteredComponentName([]);
    setTranslationText({});
    setTabErrors({});
    setErrors({});
  };

  useEffect(() => {
    resetFields();
  }, [open]);
  useEffect(() => {
    if (languagesData) {
      Object.values(languagesData?.list)?.forEach(({ iso_code }) => {
        if (!translationText[iso_code]) {
          translationText[iso_code] = {};
        }
      });
    }
    setTranslationText(translationText);
  }, [languagesData, translationText]);

  const validation = useCallback(() => {
    if (isEmpty(contentName)) {
      errors.contentNameError = "required";
    } else {
      delete errors.contentNameError;
    }
    Object.keys(translationText)?.forEach((language) => {
      if (isEmpty(translationText[language].text)) {
        if (!errors[language]) errors[language] = {};
        errors[language].text = "required";
        tabErrors[t(`shared.language.${language}`)] = {};
      } else {
        delete errors[language]?.text;
      }

      if (errors[language] && !Object.values(errors[language])?.length) {
        delete errors[language];
        delete tabErrors[t(`shared.language.${language}`)];
      }
    });
    setTabErrors({ ...tabErrors });
    setErrors({ ...errors });
  }, [translationText, t, tabErrors, errors, contentName]);

  useEffect(() => {
    if (open) validation();
  }, [contentName]);

  useEffect(() => {
    if (filteredComponentName.length)
      setComponentName(filteredComponentName[0].value);
  }, [filteredComponentName]);

  const tabContent = useMemo(() => {
    const object = {};
    if (languagesData)
      Object.values(languagesData?.list)?.forEach(({ iso_code }) => {
        object[t(`shared.language.${iso_code}`)] = (
          <Grid container spacing={2} direction={"column"}>
            <Grid xs={12}>
              <TextField
                label={t(`component.shared.${iso_code}_content`)}
                fullWidth
                value={translationText[iso_code]?.text || ""}
                onChange={(e) => {
                  translationText[iso_code].text = e.target.value;
                  setTranslationText({ ...translationText });
                  validation();
                }}
                error={!!errors[iso_code]?.text}
                helperText={errors[iso_code]?.text}
              />
            </Grid>
          </Grid>
        );
      });
    return object;
  }, [languagesData, t, translationText, errors, validation]);

  const onSubmit = () => {
    validation();
    if (!Object.values(errors).length) {
      mutation.mutate();
    }
  };
  const componentNames = useMemo(
    () => [
      {
        label: t("component.name.block.about_us"),
        value: "about_us",
        type: "block",
      },
      {
        label: t("component.name.block.contact_us"),
        value: "contact_us",
        type: "block",
      },
      {
        label: t("component.name.block.footer"),
        value: "footer",
        type: "block",
      },
      {
        label: t("component.name.block.header"),
        value: "header",
        type: "block",
      },
      {
        label: t("component.name.block.our_services"),
        value: "our_services",
        type: "block",
      },
      {
        label: t("component.name.block.warning"),
        value: "warning",
        type: "block",
      },
      { label: t("component.name.shared.btn"), value: "btn", type: "shared" },
      { label: t("component.name.shared.term"), value: "term", type: "shared" },
    ],
    [t]
  );

  useEffect(() => {
    setComponentName("");
    setFilteredComponentName(
      componentNames.filter(({ type }) => type === componentType)
    );
  }, [componentType, componentNames, setFilteredComponentName, open]);
  return (
    <>
      <BaseModal open={open} onClose={() => onClose()}>
        <h2>{t("shared.term.create_content")}</h2>
        <Grid container spacing={2} direction={"column"}>
          <Grid xs={12}>
            <FormControl fullWidth>
              <InputLabel id="component-type-label">
                {t("component.component_type")}
              </InputLabel>
              <Select
                labelId="component-type-label"
                id="component-type-select"
                value={componentType}
                label={t("component.component_type")}
                onChange={(e) => setComponentType(e.target.value)}
              >
                <MenuItem value="block">{t("component.type.block")}</MenuItem>
                <MenuItem value="shared">{t("component.type.shared")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12}>
            <FormControl fullWidth>
              <InputLabel id="component-name-label">
                {t("component.component_name")}
              </InputLabel>
              <Select
                labelId="component-name-label"
                id="component-name-select"
                value={componentName || ""}
                label={t("component.component_name")}
                onChange={(e) => {
                  setComponentName(e.target.value);
                }}
              >
                {filteredComponentName.map((element) => (
                  <MenuItem key={element.value} value={element.value}>
                    {element.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12}>
            <FormControl fullWidth>
              <InputLabel id="content-type-label">
                {t("component.content.content_type")}
              </InputLabel>
              <Select
                defaultValue={true}
                labelId="content-type-label"
                id="content-type-select"
                value={contentType}
                label={t("component.content.content_type")}
                onChange={(e) => setContentType(e.target.value)}
              >
                <MenuItem value="h1">{t("component.content.type.h1")}</MenuItem>
                <MenuItem value="h2">{t("component.content.type.h2")}</MenuItem>
                <MenuItem value="h3">{t("component.content.type.h3")}</MenuItem>
                <MenuItem value="p">{t("component.content.type.p")}</MenuItem>
                <MenuItem value="checkbox">
                  {t("component.content.type.checkbox")}
                </MenuItem>
                <MenuItem value="btn">
                  {t("component.content.type.btn")}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid container spacing={2} direction={"column"}>
            <Grid xs={12}>
              <TextField
                label={t("component.content.name")}
                fullWidth
                value={contentName}
                error={!!errors.contentNameError}
                helperText={errors.contentNameError}
                onChange={(e) => {
                  setContentName(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid xs={12}>
            <TabsComponent
              tabContent={tabContent}
              errors={Object.keys(tabErrors)}
            />
          </Grid>
          <Grid>
            <Button variant="contained" onClick={onSubmit} color="success">
              {t("shared.btn.create")}
            </Button>
          </Grid>
        </Grid>
      </BaseModal>
    </>
  );
}
