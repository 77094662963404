const LOGIN = "auth/login";
export const LOGOUT = "auth/logout";
export const USERS = "users";
export const WORKERS = "workers";
export const TRANSLATIONS = "translations";
export const ROLES = "roles";
export const SETTINGS = "settings";
export const LANGUAGES = "languages";
export const SERVICES = "services";
export const SKILLS = "skills";
export const DOWNLOAD = "download";
export const CUSTOM_FIELD = "customField";
export const PASSWORD = "password";
export const EMAIL = "email";
export default LOGIN;
