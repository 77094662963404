import { useState, useMemo } from "react";
import { Button, CircularProgress, IconButton } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { DialogComponent } from "../Dialog/Dialog";
import loadable from "@loadable/component";
import Delete from "../../util/Request/Delete";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { messageSuccess, messageError } from "../../util/Toast";
import {
  BorderColorSharp as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

/*https://loadable-components.com/docs/getting-started/*/

export function ButtonActionRenderer(props) {
  // props is ICellRenererParams. See:
  // https://www.ag-grid.com/react-grid/component-cell-renderer/#cell-renderer-component-2
  const { data, modalName, showDeleteBtn, route, queryKey } = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const onDialogClose = () => setDialogOpen(false);
  const onModalClose = () => setModalOpen(false);
  const queryClient = useQueryClient();

  const deleteFn = () =>
    Delete({
      route: route,
      key: data?.id,
    });

  const mutation = useMutation({
    mutationFn: deleteFn,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: queryKey });
      onDialogClose();
      messageSuccess("delete successfully");
    },
    onError: (error, variables, context) => {
      messageError("failed to delete");
    },
    onSettled: (data, error, variables, context) => {
      // I will fire first
    },
  });

  const onDialogDelete = () => {
    mutation.mutate();
  };

  var deleteBtn;

  if (showDeleteBtn) {
    deleteBtn = (
      <Grid xs={6}>
        <IconButton
          aria-label="Delete"
          color="error"
          onClick={() => setDialogOpen(true)}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    );
  }
  const AsyncModal = useMemo(() => {
    return loadable(() => import(`../../modals/${modalName}`));
  }, [modalName]);

  return (
    <>
      <Grid container justifyContent="center" alignItems="center" spacing={1}>
        <Grid xs={6}>
          <IconButton
            aria-label="Edit"
            color="primary"
            onClick={() => setModalOpen(true)}
          >
            <EditIcon />
          </IconButton>
        </Grid>
        {deleteBtn}
      </Grid>

      <AsyncModal
        fallback={<CircularProgress />}
        open={modalOpen}
        setOpen={setModalOpen}
        onClose={onModalClose}
        data={data}
      />

      <DialogComponent
        open={dialogOpen}
        onClose={onDialogClose}
        onDelete={onDialogDelete}
        title={"Delete Confirmation"}
        content={"Are you sure you want to delete this?"}
      />
    </>
  );
}
