import React, { useState } from "react";
import { Paper, Button } from "@mui/material";
import Put from "../../util/Request/Put";
import { useMutation } from "@tanstack/react-query";
import { messageError, messageSuccess } from "../../util/Toast";
import { SETTINGS } from "../../util/Routes/Routes";
import SocialMedia from "../../components/Form/Settings/SocialMedia/SocialMedia";
import Contact from "../../components/Form/Settings/Contact/Contact";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { isEmail, isEmpty, isMobilePhone } from "../../util/Validation";
import { useTranslation } from "react-i18next";

export default function Settings() {
  const { t } = useTranslation();
  const [data, setData] = useState({});

  const updateSettings = () =>
    Put({
      route: SETTINGS,
      key: "settings",
      body: data,
    });
  const [errors, setErrors] = useState({});
  const validateEmail = (value) => {
    const email = value !== undefined ? value : data.email.value;
    if (isEmpty(email)) {
      errors.email = t(`shared.term.required`);
    } else if (!isEmail(email)) {
      errors.email = t(`shared.term.invalid`);
    } else {
      delete errors.email;
    }
    setErrors({ ...errors });
  };

  const validatePhoneNumber = (value) => {
    const phone_number = value !== undefined ? value : data.phone_number.value;
    if (isEmpty(phone_number)) {
      errors.phone_number = t(`shared.term.required`);
    } else if (!isMobilePhone(phone_number)) {
      errors.phone_number = t(`shared.term.invalid`);
    } else {
      delete errors.phone_number;
    }
    setErrors({ ...errors });
  };
  const mutation = useMutation({
    mutationFn: updateSettings,
    onSuccess: (data) => {
      messageSuccess("created successfully");
    },
    onError: (error, variables, context) => {
      messageError("failed to create");
    },
  });
  const onUpdate = () => {
    validateEmail();
    validatePhoneNumber();
    if (!Object.values(errors).length) {
      mutation.mutate();
    }
  };
  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 240,
          }}
        >
          <SocialMedia setData={setData} errors={errors} />
        </Paper>
      </Grid>

      <Grid xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Contact
            setData={setData}
            errors={errors}
            validateEmail={validateEmail}
            validatePhoneNumber={validatePhoneNumber}
          />
        </Paper>
      </Grid>
      <Grid xs={12}>
        <Button variant="contained" onClick={onUpdate}>
          {t(`shared.btn.update`)}
        </Button>
      </Grid>
    </Grid>
  );
}
