import * as React from "react";
import Table from "../../components/Table/Table";
import Get from "../../util/Request/Get";
import { WORKERS } from "../../util/Routes/Routes";
import { useQuery } from "@tanstack/react-query";
function Workers() {
  const getWorkers = () => Get({ route: WORKERS });

  const { data, error, isLoading } = useQuery({
    queryKey: ["workers"],
    queryFn: getWorkers,
  });

  const columnDefs = [
    { headerName: "Brand", field: "username" },
    { headerName: "Model", field: "model" },
    { headerName: "Price", field: "price" },
    { headerName: "Year", field: "year" },
    { headerName: "Color", field: "color" },
    { headerName: "Type", field: "type" },
    { headerName: "Country", field: "country" },
  ];
  return <Table data={data?.users} columnDefs={columnDefs} />;
}

export default Workers;
