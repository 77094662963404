import Table from "../../../components/Table/Table";
import Get from "../../../util/Request/Get";
import { TRANSLATIONS } from "../../../util/Routes/Routes";
import { useQuery } from "@tanstack/react-query";
import { ButtonActionRenderer } from "../../../components/Table/ButtonActionRenderer";
import { messageError } from "../../../util/Toast";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo } from "react";
import i18next from "i18next";

export default function BlocksContents() {
  const { t } = useTranslation();

  const getBlocksComponents = () =>
    Get({
      route: `${TRANSLATIONS}/getBlocksComponents`,
    });
  const { data, refetch } = useQuery({
    queryKey: ["blocks-contents"],
    queryFn: getBlocksComponents,
    onError: (error) => {
      messageError();
    },
  });

  const columnDefs = [
    { headerName: "Component", field: "translation", flex: 1 },

    {
      headerName: "Actions",
      field: "actions",
      cellRenderer: ButtonActionRenderer,
      cellRendererParams: {
        modalName: "TextContentModal",
        showDeleteBtn: false,
      },
    },
  ];
  const list = useMemo(() => {
    const array = [];
    data?.list.forEach((item) => {
      array.push({
        translation: t(`component.name.${item.translation}`),
        value: item.value,
        label: item.label,
      });
    });
    return array;
  }, [data, t]);

  useEffect(() => {
    refetch();
  }, [i18next.languages, refetch]);

  return <Table data={list} columnDefs={columnDefs} />;
}
