import {
  Button,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Collapse,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { ExpandLess, ExpandMore, Dashboard, People, Psychology, HomeRepairService } from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import CustomFieldModal from "../../modals/CustomFieldModal";
import SkillsModal from "../../modals/SkillsModal";

export default function MainListItem() {
  const { t } = useTranslation();
  const location = useLocation();
  const [customFieldModalOpen, setCustomFieldModalOpen] = useState(false);
  const onClose = () => setCustomFieldModalOpen(false);
  const menuRoutes = {
    content: ["/blocks-contents", "/shared-contents"],
    services: ["/services"],
    skills: ["/skills"],
  };
  const [open, setOpen] = useState({
    content: menuRoutes.content.includes(location.pathname),
    services: menuRoutes.services.includes(location.pathname),
    //skills: menuRoutes.skills.includes(location.pathname),
  });

  const handleClick = (section) => {
    setOpen((prevState) => ({
      ...Object.fromEntries(
        Object.entries(prevState).map(([key, value]) => [
          key,
          key === section && !value,
        ])
      ),
    }));
  };
  return (
    <React.Fragment>
      {/* <Link to="/dashboard">
        <ListItemButton>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={t("nav.dashboard")} />
        </ListItemButton>
      </Link> */}
      <ListItemButton onClick={() => handleClick("content")}>
        <ListItemIcon>
          <Dashboard />
        </ListItemIcon>
        <ListItemText primary={t("nav.content.label")} />
        {open.content ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open.content} timeout="auto" unmountOnExit>
        <ListSubheader component="div" inset>
          <Link to="/blocks-contents">
            <ListItemButton>
              <ListItemText primary={t("nav.content.blocks")} />
            </ListItemButton>
          </Link>
          <Link to="/shared-contents">
            <ListItemButton>
              <ListItemText primary={t("nav.content.shared")} />
            </ListItemButton>
          </Link>
          <CustomFieldModal open={customFieldModalOpen} onClose={onClose} />
          <ListItemButton onClick={() => setCustomFieldModalOpen(true)}>
            <ListItemText primary={t("shared.btn.create")} />
          </ListItemButton>
        </ListSubheader>
      </Collapse>

      <Link to="/users" onClick={() => handleClick("users")}>
        <ListItemButton>
          <ListItemIcon>
            <People />
          </ListItemIcon>
          <ListItemText primary={t("nav.users")} />
        </ListItemButton>
      </Link>
      {/* <Link to="/workers">
        <ListItemButton>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary={t("nav.workers")} />
        </ListItemButton>
      </Link> */}
      {/* <Link to="/employers">
        <ListItemButton>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary={t("nav.employers")} />
        </ListItemButton>
      </Link> */}
      {/* <Link to="/jobs">
        <ListItemButton>
          <ListItemIcon>
            <LayersIcon />
          </ListItemIcon>
          <ListItemText primary={t("nav.jobs")} />
        </ListItemButton>
      </Link> */}

      <Link to="/services" onClick={() => handleClick("services")}>
        <ListItemButton>
          <ListItemIcon>
            <HomeRepairService />
          </ListItemIcon>
          <ListItemText primary={t("nav.services")} />
        </ListItemButton>
      </Link>
      
      <Link to="/skills" onClick={() => handleClick("skills")}>
        <ListItemButton>
          <ListItemIcon>
            <Psychology />
          </ListItemIcon>
          <ListItemText primary={t("nav.skills")} />
        </ListItemButton>
      </Link>
    </React.Fragment>
  );
}
