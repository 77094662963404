import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
export const DialogComponent = ({
  open,
  onClose,
  onSave,
  onDelete,
  title,
  content,
}) => {
  return (
    <Dialog
      PaperProps={{ sx: { minWidth: "25%" } }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        <Grid container spacing={1}>
          <Grid xs={11}> {title}</Grid>
          <Grid xs={1}>
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers> {content}</DialogContent>
      <DialogActions>
        {onDelete ? (
          <Button onClick={onDelete} color="primary">
            Delete
          </Button>
        ) : null}
        {onSave ? (
          <Button onClick={onSave} color="primary">
            Save
          </Button>
        ) : null}
        <Button onClick={onClose} color="primary">
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
