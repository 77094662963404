import "./App.css";
import { Route, Routes } from "react-router-dom";
import PageLayout from "./components/PageLayout/PageLayout";
import { ProtectedRoute } from "./components/ProtectedRoute/ProtectedRoute";
import Login from "./pages/Login/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/Dashboard/Dashboard";
import Users from "./pages/Users/Users";
import Workers from "./pages/Workers/Workers";
import Services from "./pages/Services/Services";
import Settings from "./pages/Settings/Settings";
import SharedContents from "./pages/Content/SharedContent/SharedContents";
import BlocksContents from "./pages/Content/BlocksContents/BlocksContents";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail";
import VerifyEmailBeforeAccess from "./pages/VerifyEmail/VerifyEmailBeforeAccess";
import Skills from "./pages/Skills/Skills";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<PageLayout />}>
          <Route index element={<Login />} />
          <Route
            path="reset-password/:email/:token"
            element={<ResetPassword />}
          />
          <Route path="email/verify/:status" element={<VerifyEmail />} />
          <Route
            path="dashboard"
            element={
              <ProtectedRoute redirectPath="/">
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="blocks-contents"
            element={
              <ProtectedRoute
                redirectPath="/permission-denied"
                isAllowed={"admin"}
              >
                <BlocksContents />
              </ProtectedRoute>
            }
          />
          <Route
            path="shared-contents"
            element={
              <ProtectedRoute
                redirectPath="/permission-denied"
                isAllowed={"admin"}
              >
                <SharedContents />
              </ProtectedRoute>
            }
          />
          <Route
            path="verify-email"
            element={
              <ProtectedRoute redirectPath="/">
                <VerifyEmailBeforeAccess />
              </ProtectedRoute>
            }
          />
          <Route
            path="users"
            element={
              <ProtectedRoute redirectPath="/">
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            path="workers"
            element={
              <ProtectedRoute redirectPath="/">
                <Workers />
              </ProtectedRoute>
            }
          />
          <Route
            path="services"
            element={
              <ProtectedRoute redirectPath="/">
                <Services />
              </ProtectedRoute>
            }
          />
          <Route
            path="skills"
            element={
              <ProtectedRoute redirectPath="/">
                <Skills />
              </ProtectedRoute>
            }
          />
          <Route
            path="settings"
            element={
              <ProtectedRoute redirectPath="/">
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path="*"
            element={
              <ProtectedRoute redirectPath="/">
                <p>There's nothing here: 404!</p>
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
      <ToastContainer
        position="bottom-right"
        autoClose={1500}
        theme="colored"
      />
    </div>
  );
}
export default App;
